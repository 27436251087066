<template>
    <div class="container-fluid" id="dashboard" style="padding: 1rem; overflow-y: auto; background-color: white;">
        <div class="row mt-2" v-on:keypress.esc="fullScreenExit()">
            <div class="col-md-12 p-5">
                <div class="row toggleFullScreen">
                    <div class="title-company">
                        <span style="font-family: 'Jost Variable'">
                            {{ user.company.nameCompany.toUpperCase() }}
                        </span>
                    </div>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
                        <div class="col-md-1">
                            <button class="btn" type="button" @click="seeHeaderFilter()">
                                <box-icon name='customize'></box-icon>
                            </button>
                        </div>
                        <div id="filtros" class="col md-12">
                            <div class="row">
                                <div class="col-md-2">
                                    <input type="text" class="form-control me-2" placeholder="Buscar" v-model="filter"
                                        @change="filteredRows()" />

                                </div>
                                <div class="col-md-3 input-group" style="width: 250px">
                                    <span class="input-group-text" id="filterStart">Desde:</span>
                                    <input type="date" class="form-control me-2" placeholder="dd/mm/yyyy"
                                        aria-label="start" aria-describedby="filterStart" v-model="filterStart"
                                        @change="filteredRows()" />

                                </div>
                                <div class="col-md-3 input-group" style="width: 250px">
                                    <span class="input-group-text" id="filterEnd">Hasta:</span>
                                    <input type="date" class="form-control me-2" placeholder="dd/mm/yyyy"
                                        aria-label="end" aria-describedby="filterEnd" v-model="filterEnd"
                                        @change="filteredRows()" />
                                </div>

                                <select class="form-select" id="statusSelect" v-model="filterStatus"
                                    style="width: 230px" @change="filteredRows()">
                                    <option value="" selected>-- Todos los Estatus --</option>
                                    <option class="bg-danger" v-for="state in statusFilter" v-bind:key="state._id"
                                        :value="state._id">
                                        {{ state.name }}
                                    </option>
                                </select>


                                <div class="col-md-2">

                                    <select class="form-select" id="nivelSelect" v-model="filterNivel"
                                        style="width: 230px" @change="filteredRows()">
                                        <option value="" selected>-- Todos los Niveles --</option>
                                        <option class="bg-danger" v-for="nivel in nivelFilter" v-bind:key="nivel._id"
                                            :value="nivel._id">
                                            {{ nivel.name }}
                                        </option>
                                    </select>
                                </div>

                            </div>
                        </div>

                        <button class="btn md-2" type="button" @click="fullScreen()" v-if="!isfullScreen"
                            data-bs-toggle="tooltip" data-bs-placement="bottom" title="Pantalla completa">
                            <box-icon name='fullscreen'></box-icon>
                        </button>
                        <button class="btn" type="button" @click="fullScreenExit()" v-if="isfullScreen">
                            <box-icon name='exit-fullscreen'></box-icon>
                        </button>
                    </div>
                </div>
                <div
                    style="display: flex; justify-content: center; align-items: center; padding: 2rem; min-width: 90%;">
                    <table
                        class="table table-responsive table-bordered table-hover align-middle table-striped caption-center"
                        style="font-family: system-ui;">
                        <thead>
                            <tr align="center">
                                <th class="col" width="150" scope="col">No. Vacante</th>
                                <th class="col" scope="col">Cía/División</th>
                                <th class="col" scope="col">Vacante</th>
                                <th class="col" scope="col">Área</th>
                                <th class="col" scope="col">Responsable</th>
                                <th class="col" scope="col">Ubicación</th>
                                <th class="col" scope="col">Fecha de Inicio</th>
                                <th class="col" scope="col">DT</th>
                                <th class="col" scope="col">Status</th>
                                <th class="col" scope="col">KPI</th>
                                <th class="col" scope="col">Nivel</th>
                                <th class="col" scope="col">En Progreso</th>
                                <th class="col" scope="col">Comentarios / Acciones</th>
                            </tr>
                        </thead>
                        <tbody style="text-align: center;">
                            <tr v-for="(dashboard, index) in dataPage" v-bind:key="`dashboard-${index}`">

                                <td v-bind:key="dashboard.control" style="text-align: center;">{{
            dashboard.control
        }}</td>

                                <td v-bind:key="dashboard.company" style="text-align: center;">{{
            dashboard.company
        }}</td>

                                <td width="30%" v-bind:key="dashboard.vacante" style="text-align: center;">{{
            dashboard.vacante
        }}</td>

                                <td v-bind:key="dashboard.area" style="text-align: center;">{{
            dashboard.area
        }}</td>

                                <td v-bind:key="dashboard.responsable" style="text-align: center;">{{
            dashboard.responsable
        }}</td>

                                <td v-bind:key="dashboard.federalEntities" style="text-align: center;">{{
            dashboard.federalEntities
        }}</td>

                                <td align="center">{{ moment.utc(dashboard.start).format("DD/MM/YYYY") }}</td>


                                <td style="text-align: center;">{{ tiempoTranscurrido(moment.utc(dashboard.start),
            dashboard.status, dashboard.closingDate ? moment.utc(dashboard.closingDate) : '') }}
                                </td>

                                <td v-bind:key="dashboard._id_estatus" style="text-align: center;">
                                    {{ getStatusName(dashboard._id_estatus) }}
                                </td>

                                <td>
                                    <div class="column-indicador" :class="{
            'bg-danger': tiempoTranscurrido(moment.utc(dashboard.start), dashboard.status, dashboard.closingDate ? moment.utc(dashboard.closingDate) : '') > dashboard.meta,
            'bg-warning': tiempoTranscurrido(moment.utc(dashboard.start), dashboard.status, dashboard.closingDate ? moment.utc(dashboard.closingDate) : '') > dashboard.minimumLimit && tiempoTranscurrido(moment.utc(dashboard.start)) <= dashboard.meta,
            'bg-success': tiempoTranscurrido(moment.utc(dashboard.start), dashboard.status, dashboard.closingDate ? moment.utc(dashboard.closingDate) : '') <= dashboard.minimumLimit,
        }">
                                    </div>
                                </td>
                                <td v-bind:key="dashboard._id_nivel" style="text-align: center;">{{
            getNivelName(dashboard._id_nivel)
        }}</td>

                                <td v-bind:key="dashboard.nivel" style="text-align: center;">{{
            dashboard.progressCandidates > 0 ? dashboard.progressCandidates : ''
        }}</td>

                                <td align="center">

                                    <box-icon name='edit' type='solid' style="cursor: pointer;"
                                        @click="editDashboard(dashboard._id)"></box-icon>

                                </td>

                            </tr>
                            <tr v-if="dataPage.length === 0">
                                <td colspan="16">
                                    <strong class="t-text-danger"> No hay elementos para mostrar</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Paginador -->
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                        <li v-if="this.actual > 1" class="page-item" v-on:click="getPreviousPage()">
                            <a class="page-link" href="#">Anterior</a>
                        </li>
                        <li v-for="(page, i) in totalPages()" :key="i + 1" v-on:click="getDataPages(page)"
                            v-bind:class="isActive(page)" class="page-item">
                            <a v-if="page < this.actual + 7 && page > this.actual - 7" class="page-link" href="#">{{
                                page
                                }}</a>
                        </li>
                        <li class="page-item" v-on:click="getNextPage()">
                            <a class="page-link" href="#">Siguiente</a>
                        </li>
                    </ul>
                    <ul class="pagination justify-content-center">
                        <li class="page-item">
                            Página <b>{{ this.actual }}</b> de <b>{{ totalPages() }}</b> - Total de <b>{{
                                this.countElements }}</b> vacantes disponibles
                        </li>
                    </ul>
                </nav>

            </div>

        </div>
    </div>
</template>

<script>

import { ref } from "vue";
import { CompanyService } from "@/services";
import moment from 'moment-timezone';
moment.tz.setDefault("America/Mexico_City");

export default {
    data: () => ({
        seeFilter: false,
        filter: "",
        filterStart: "",
        filterEnd: "",
        filterStatus: "",
        filterNivel: "",
        //statusFilter: ['En progreso', 'Crítico', 'Holding', 'Cancelado', 'Terminado', 'Cubierto Interno', 'Feedback'],
        // nivelFilter: ['I. Becario - Practicante', 'II. Staff', 'III. - Mando Medio', 'IV. Gerente', 'V. Director', 'VI. Ejecutivo'],
        nivelFilter: "",
        statusFilter: "",
        filtered: {
            filter: "",
            filterStart: "",
            filterEnd: "",
            filterStatus: "",
            filterNivel: "",
            userId: ""
        },
        table: null,
        elementSearch: "",
        elementPage: 9,
        dataPage: [],

        viewDataArray: {
            created_at: new Date(),
        },
        actual: 1,
        search: "",
        id: "",
        status: null,
        moment: moment,
        userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        dataDashboard: ref(""),
        isfullScreen: false,
        user: JSON.parse(localStorage.getItem("user")),
        countElements: 0,
        timeoutID: ""
    }),
    created() {
        window.addEventListener('keydown', (e) => {
            if (e.key == 'Escape') {
                this.isfullScreen = !this.isfullScreen;
            }
        });
    },
    beforeMount() {
        this.getNiveles();
        this.getEstatus();
        this.filtered.userId = this.user._id
        let sFilter = localStorage.getItem('filter');
        let sStart = localStorage.getItem('filterStart');
        let sEnd = localStorage.getItem('filterEnd');
        let sStatus = localStorage.getItem('filterStatus');
        let sNivel = localStorage.getItem('filterNivel');

        if (sFilter != '' || sStart != '' || sEnd != '' || sStatus != '' || sNivel != '') {
            this.filtered.filter = sFilter ? sFilter : '';
            this.filtered.filterStart = sStart ? moment.utc(sStart).format('YYYY-MM-DD') : '';
            this.filtered.filterEnd = sEnd ? moment.utc(sEnd).format('YYYY-MM-DD') : '';
            this.filtered.filterStatus = sStatus ? sStatus : '';
            this.filtered.filterNivel = sNivel ? sNivel : '';

            this.filter = sFilter ? sFilter : '';
            this.filterStart = sStart ? moment.utc(sStart).format('YYYY-MM-DD') : '';
            this.filterEnd = sEnd ? moment.utc(sEnd).format('YYYY-MM-DD') : '';
            this.filterStatus = sStatus ? sStatus : '';
            this.filterNivel = sNivel ? sNivel : '';
            this.getDashboardCompanyFilter();

        } else {
            this.getDashboardCompany(this.user._id);
        }
    },
    watch: {
        filter(newFilter) {
            localStorage.setItem('filter', newFilter);
        },

        filterStart(newFilterStart) {
            localStorage.setItem('filterStart', this.filterStart ? moment.utc(newFilterStart) : "");

        },
        filterEnd(newFilterEnd) {
            localStorage.setItem('filterEnd', this.filterEnd ? moment.utc(newFilterEnd) : "");
        },
        filterStatus(newFilterStatus) {
            localStorage.setItem('filterStatus', newFilterStatus);
        },
        filterNivel(newFilterNivel) {
            localStorage.setItem('filterNivel', newFilterNivel);
        }
    },
    methods: {
        async getDashboardCompany(userId) {
            const response = await CompanyService.getDasboardCompanyByUserId(userId);
            const { data } = response;
            this.dataDashboard = data;
            this.countElements = this.dataDashboard.data.length;
            this.dataDashboard.data.sort(function (x, y) {
                var firstDate = new Date(x.start),
                    SecondDate = new Date(y.start);

                return firstDate > SecondDate ? -1 : 1;
            });


            if (this.dataDashboard.data.length > 0) {
                this.viewData(this.dataDashboard);
                this.getDataPages(1);
            }
        },
        async getDashboardCompanyFilter() {
            const response = await CompanyService.getDasboardCompanyFilter(this.filtered); //para verlos todos: CompanyService.getDasboardCompany();

            const { data } = response;

            this.dataDashboard = { ...data };

            this.countElements = this.dataDashboard.data.length;
            this.dataDashboard.data.sort(function (x, y) {
                var firstDate = new Date(x.start),
                    SecondDate = new Date(y.start);

                return firstDate > SecondDate ? -1 : 1;
            });

            if (this.dataDashboard.data.length > 0) {
                this.viewData(this.dataDashboard);
                this.getDataPages(1);
            } else {
                this.dataPage = [];
            }
        },
        viewData(vacante) {
            this.viewDataArray = vacante;
            this.id = vacante._id;
        },
        fullScreen() {
            this.isfullScreen = true;

            this.timeoutID = setInterval(() => {
                this.carrucelData();
            }, 7000);

            this.toogleFullScreen();
        },
        fullScreenExit(event) {
            console.log(event);
            clearTimeout(this.timeoutID);
            this.isfullScreen = false;
            this.toogleFullScreen();
        },
        getFullScreenDashboard() {
            return document.fullscreenElement
                || document.webkitFullscreenElement
                || document.mozFullscreenElement
                || document.msFullscreenElement
        },
        toogleFullScreen() {
            if (this.getFullScreenDashboard()) {
                document.exitFullscreen();
            } else {
                document.getElementById("dashboard").requestFullscreen().catch(console.log);
            }
        },
        totalPages() {
            let total = 0;
            if (this.dataDashboard.data) {
                total = Math.ceil(parseInt(this.dataDashboard.data.length) / this.elementPage);
            }
            return total;
        },
        getDataPages(numberPage) {
            this.actual = numberPage;
            this.dataPage = [];
            const start = numberPage * this.elementPage - this.elementPage;
            const end = numberPage * this.elementPage;
            this.dataPage = this.dataDashboard.data.slice(start, end);
        },
        getPreviousPage() {
            if (this.actual > 1) {
                this.actual--;
            }
            this.getDataPages(this.actual);
        },
        getNextPage() {
            if (this.actual < this.totalPages()) {
                this.actual++;
            }
            this.getDataPages(this.actual);
        },
        isActive(numberPage) {
            return numberPage == this.actual ? "active" : "";
        },
        gestionarVacante() {

            this.$router.push('/formulario-vacante');
        },
        tiempoTranscurrido(start, status, end) {

            // var from = moment.utc(start);
            // var to = moment.utc(new Date());
            var from = moment(start).tz(this.userTimeZone);
            var to = moment(new Date()).tz(this.userTimeZone);
            var days = 0;
            let nameStatus = this.statusFilter[this.statusFilter.length - 1];
            let fin = end ? moment.utc(end) : '';

            if (status != nameStatus && !fin) {
                while (!from.isAfter(to)) {
                    // Si no es sabado ni domingo
                    if (from.isoWeekday() !== 6 && from.isoWeekday() !== 7) {
                        days++;
                    }
                    from.add(1, 'days');
                }
            }
            else {
                if (fin) {
                    while (!from.isAfter(fin)) {
                        // Si no es sabado ni domingo
                        if (from.isoWeekday() !== 6 && from.isoWeekday() !== 7) {
                            days++;
                        }
                        from.add(1, 'days');
                    }
                }

            }
            return days;
        },
        seeHeaderFilter() {
            this.seeFilter = !this.seeFilter;
            this.filter = "";
            this.filterStart = "";
            this.filterEnd = "";
            this.filterStatus = "";
            this.filterNivel = "";

            localStorage.setItem('filter', "");
            localStorage.setItem('filterStart', "");
            localStorage.setItem('filterEnd', "");
            localStorage.setItem('filterStatus', "");
            localStorage.setItem('filterNivel', "");

            this.getDashboardCompany(this.user._id);

        },
        editDashboard(id) {
            this.$router.push('/formulario-vacante/' + id);
        },
        detailsDashboard(id) {
            this.$router.push('/formulario-vacante/' + id + '/1');
        },
        localizeDate(date) {
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },
        async filteredRows() {

            let searchTerm = this.filter ? this.filter.toLowerCase().trim() : '';
            let searchTermStart = this.filterStart ? this.filterStart.toLowerCase().trim() : '';
            let searchTermEnd = this.filterEnd ? this.filterEnd.toLowerCase().trim() : '';
            let searchTermStatus = this.filterStatus;
            let searchTermNivel = this.filterNivel;

            this.filtered.filter = this.filter ? this.filter : '';
            this.filtered.filterStart = this.filterStart ? moment.utc(this.filterStart) : "";
            this.filtered.filterEnd = this.filterEnd ? moment.utc(this.filterEnd) : "";
            this.filtered.filterStatus = this.filterStatus;
            this.filtered.filterNivel = this.filterNivel;

            /* localStorage.setItem('filter', this.filter);
             localStorage.setItem('filterStart', this.filterStart ? moment.utc(this.filterStart) : "");
             localStorage.setItem('filterEnd', this.filterEnd ? moment.utc(this.filterEnd) : "");
             localStorage.setItem('filterStatus', this.filterStatus);
             localStorage.setItem('filterNivel', this.filterNivel);*/

            if (searchTerm != "" || searchTermStatus != "" || searchTermNivel != "" || (searchTermStart != "" && searchTermEnd != "")) {

                await this.getDashboardCompanyFilter();
            }


            /* if (this.dataDashboard) {
                 this.getDataPages(this.actual);
                 this.totalPages();
             }*/

            if (searchTerm == "" && this.filterStatus == "" && this.filterNivel == "" && (this.filterStart == "" && this.filterEnd == "")) {
                this.getDashboardCompany(this.user._id);
            }
        },
        async getPostulaciones(vacanteId) {
            const response = await CompanyService.getPostulantesByVacante(vacanteId);
            const { data } = response;
            return data;
        },
        carrucelData() {

            let total = this.totalPages();
            if (total > 1) {

                if (this.actual == total) {

                    this.getDataPages(1);
                } else {
                    this.getNextPage();
                }
            }

        },
        async getNiveles() {
            try {
                const response = await CompanyService.getNiveles();
                const { data } = response.data;
                this.nivelFilter = data;

            } catch (e) {
                console.log(e);
            }
        },
        async getEstatus() {
            try {
                const response = await CompanyService.getEstatus();
                const { data } = response.data;
                this.statusFilter = data;
                console.log('this.statusFilter =>', this.statusFilter);

            } catch (e) {
                console.log(e);
            }
        },
        getStatusName(statusId) {
            const status = this.statusFilter.find(state => state._id === statusId);
            return status ? status.name : '';
        },
        getNivelName(nivelId) {
            const nivel = this.nivelFilter.find(nivel => nivel._id === nivelId);
            return nivel ? nivel.name : '';
        }
    }
}


</script>

<style scoped land="scss">
@import "../styles/dashboard.scss";
</style>